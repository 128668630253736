import './utils/db'
import { Router } from '@reach/router'
import React, {useState} from 'react'
import './App.css'
import MainView from './layout/Main'
import LoginPage from './layout/LoginForm'

function App() {

  const [loggedIn, setLoggedIn] = useState(false)
  const [error, setError] = useState(false)

  const loginAction = (username, password) => {
    if (username === 'admin' && password === 'password')
      setLoggedIn(true)
    else
      setError(true)
  }

  if (!loggedIn) {
    return (
      <LoginPage onLogin={loginAction} isLoggedIn={loggedIn} error={error} />
    )
  } else {
    return (
      <div className="App">
        <Router>
          <MainView path="/*" isLoggedIn={loggedIn}/>
          <LoginPage path="/login" onLogin={loginAction} isLoggedIn={loggedIn} error={error} />
        </Router>
      </div>
    )
  }
}

export default App

