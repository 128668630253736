import React, { Component } from 'react'
import {PageHeader} from 'antd'
import {navigate} from '@reach/router'
import { PDFDocument, StandardFonts } from 'pdf-lib'
import PDFRenderer from '../../utils/PDFRenderer'
import store from '../../utils/fortune-db'
import moment from 'moment'

async function renderPDF(manifest) {
  const url = '/10304.pdf'
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  if (manifest) {
    const font = await pdfDoc.embedFont(StandardFonts.Courier)

    const pages = pdfDoc.getPages()
    const form = pages[0]
    const { height } = form.getSize()
    const renderer = new PDFRenderer()

    ///////////////
    // Section 1 //
    ///////////////
    let section = renderer.getProps(height, font, '1')
    let sectionData = manifest.form['1']
    const drawSafe = (text, config) => {
      if (text) 
        form.drawText(text, config)
    }
    drawSafe(sectionData.date && moment(sectionData.date).format('M/D/YYYY h:mm A'), section('date'))
    drawSafe(sectionData.registration, section('registration'))
    if (sectionData.phone)
      drawSafe(sectionData.phone.substr(0, 3), section('areaCode'))
    if (sectionData.phone)
      drawSafe(`–${sectionData.phone.substr(3, 3)}–${sectionData.phone.substr(6)}`, section('phone'))
    drawSafe(sectionData.companyName, section('companyName'))
    drawSafe(sectionData.streetAddress, section('streetAddress'))
    drawSafe(`${sectionData.city}, ${sectionData.state}, ${sectionData.zipCode}`, section('city'))
    if (sectionData.passengerTireCount)
      drawSafe(''+sectionData.passengerTireCount, section('passengerTireCount'))
    if (sectionData.truckTireCount)
      drawSafe(''+sectionData.truckTireCount, section('truckTireCount'))
    if (sectionData.generatorWasCharged) {
      drawSafe('X', section('generatorChargedCheckYes'))
    } else {
      drawSafe('X', section('generatorChargedCheckNo'))
    }
    drawSafe(sectionData.signerName, section('signerName'))

    ///////////////
    // Section 2 //
    ///////////////
    sectionData = manifest.form['2']
    section = renderer.getProps(height, font, '2')
    drawSafe(sectionData.companyName, section('companyName'))
    drawSafe(sectionData.registration, section('registration'))
    drawSafe(sectionData.phone, section('driversLicenseNumber'))
    drawSafe(sectionData.signerName, section('signerName'))

    ///////////////
    // Section 3 //
    ///////////////
    sectionData = manifest.form['3']
    section = renderer.getProps(height, font, '3')
    drawSafe(sectionData.companyName, section('companyName'))
    drawSafe(sectionData.registration, section('registration'))
    drawSafe(sectionData.phone, section('driversLicenseNumber'))
    drawSafe(sectionData.signerName, section('signerName'))

    ///////////////
    // Section 4 //
    ///////////////
    sectionData = manifest.form['4']
    section = renderer.getProps(height, font, '4')
    drawSafe(sectionData.registration && sectionData.date && `${sectionData.registration}  ${moment(sectionData.date).format('M/D/YYYY h:mmA')}`, section('registration'))
    if (sectionData.passengerTireCount)
      drawSafe(''+sectionData.passengerTireCount, section('passengerTireCount'))
    if (sectionData.truckTireCount)
      drawSafe(''+sectionData.truckTireCount, section('truckTireCount'))
    if (sectionData.phone)
      drawSafe(sectionData.phone.substr(0, 3), {
        ...section('areaCode'),
        size: 7
      })
    if (sectionData.phone)
      drawSafe(`–${sectionData.phone.substr(3, 3)}–${sectionData.phone.substr(6)}`, {
        ...section('phone'),
        size: 9
      })
    drawSafe(sectionData.companyName, section('companyName'))
    drawSafe(sectionData.streetAddress, section('streetAddress'))
    drawSafe(sectionData.city && sectionData.state && sectionData.zip && `${sectionData.city}, ${sectionData.state}, ${sectionData.zipCode}`, section('city'))

    ///////////////
    // Section 5 //
    ///////////////
    sectionData = manifest.form['5']
    section = renderer.getProps(height, font, '5')
    drawSafe(sectionData.registration && sectionData.date && `${sectionData.registration}  ${moment(sectionData.date).format('M/D/YYYY h:mmA')}`, section('registration'))
    if (sectionData.passengerTireCount)
      drawSafe(''+sectionData.passengerTireCount, section('passengerTireCount'))
    if (sectionData.truckTireCount)
      drawSafe(''+sectionData.truckTireCount, section('truckTireCount'))
    if (sectionData.phone)
      drawSafe(sectionData.phone.substr(0, 3), { ...section('areaCode'), size: 9 })
    if (sectionData.phone)
      drawSafe(`–${sectionData.phone.substr(3, 3)}–${sectionData.phone.substr(6)}`, section('phone'))
    drawSafe(sectionData.companyName, section('companyName'))
    drawSafe(sectionData.streetAddress, section('streetAddress'))
    drawSafe(sectionData.city && sectionData.state && sectionData.zip && `${sectionData.city}, ${sectionData.state}, ${sectionData.zipCode}`, section('city'))

    sectionData = manifest.form['adjustments']
    section = renderer.getProps(height, font, 'adjustments')
    if (sectionData.passengerTireCount)
      drawSafe(''+sectionData.passengerTireCount, section('passengerTireCount'))
    if (sectionData.truckTireCount)
      drawSafe(''+sectionData.truckTireCount, section('truckTireCount'))
    drawSafe('Just because', section('reason'))

    sectionData = manifest.form['final']
    section = renderer.getProps(height, font, 'final')
    drawSafe('Harold Gallagher', { ...section('printNameOne'), size: 12 })
    drawSafe('Tom Gibbs', { ...section('printNameTwo'), size: 12 })
  }

  const pdfBytes = await pdfDoc.saveAsBase64()
  return pdfBytes
}

class PDFView extends Component {

  state = {
    document: null
  }

  async componentDidMount() {
    const {payload:{records:[ manifest ]}} = await store.find('manifest', this.props.id)
    const document = await renderPDF(manifest)
    this.setState({ document, manifest })
  }

  handleBack = (id) => {
    return () => {
      navigate(`/manifest/${id}`)
    }
  }

  render() {
    return (
      <div>
        <PageHeader
          title='Manifest Document'
          onBack={this.handleBack(this.props.id)}
        />
        <div>
          {!!this.state.document && <embed style={{width:'100%',minHeight:'900px'}}  src={`data:application/pdf;base64,${this.state.document}`} />}
        </div>
      </div>
    )
  }
}

export default PDFView
