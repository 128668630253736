import React, { useRef, useState } from 'react'
import { Form, Button, Input, Icon, Checkbox, Alert } from 'antd'
import { Redirect } from '@reach/router'
import { Layout } from 'antd'

const { Content, Footer } = Layout


const LoginPage = props => {
  const [showHint, setShowHint]= useState(false)
  const usernameField = useRef(null)
  const passwordField = useRef(null)
  
  function submitForm(e) {
    e.preventDefault()
    props.onLogin(usernameField.current.state.value, passwordField.current.state.value)
  }

  if (props.loggedIn) {
    return (
      <>
        <Redirect noThrow to="/" />
      </>
    )
  } else {
    return (
      <Layout className="layout">
        <Content className="login-page">
          <Form className="login-form" onSubmit={submitForm}>
            {props.error &&
              <Alert showIcon type='error' message='Error! Invalid username or password'/>}
            <Form.Item label="Username">
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Username"
                ref={usernameField}
              />
            </Form.Item>
            <Form.Item label="Password">
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Password"
                ref={passwordField}
              />
            </Form.Item>
            <Form.Item>
              {showHint && <p style={{textAlign:'center'}}>{'Hint, try "admin" & "password"'} </p>}
              <Checkbox>Remember me</Checkbox>
              <Button type='link'
                className="login-form-forgot"
                onClick={() => {setShowHint(true)}}
              >
                Need help?
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Content>
        <Footer></Footer>
      </Layout>
    )
  }
}

export default LoginPage
