import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

const NotFound = () => {
  return (
    <Title level={1}>404 not found</Title>
  );
}

export default NotFound;
