import fortune from 'fortune'
import idbAdapter from 'fortune-indexeddb'
import { updatedAt } from './db-updated.json'
import bootstrap from './bootstrap'

const recordTypes = {
  manifest: {
    fromLocation: String,
    datePickedUp: Date,
    destLocation: String,
    status: String,
    currentLocation: String,
    transporter: String,
    step: String,
    form: Object 
  },
  user: {
    username: String,
    password: String
  },
  stub: {
    time: Date
  }
}

const store = fortune(recordTypes, {
  adapter: [ idbAdapter, {
    name: 'allied_db'
  }]
})


;(async function() {
  const stub = await store.find('stub')
  if (!stub.payload.records.length) {
    await store.create('stub', [{
      time: new Date()
    }])
  } else {
    const lastUpdate = new Date(updatedAt)
    if (lastUpdate > stub.payload.records[0].time) {
      console.log('needs update!')
      await store.delete('stub', stub.payload.records[0].id)
      await bootstrap()
      await store.create('stub', [{
        time: lastUpdate
      }])
    }
  }
})()

export default store
