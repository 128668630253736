import { message } from 'antd'

const info = (text) => {
  return () => {
    return message.info(text)
  }
}

const warn = (text) => {
  return () => {
    return message.warn(text)
  }
}

const warning = (text) => {
  return () => {
    return message.warning(text)
  }
}

const error = (text) => {
  return () => {
    return message.error(text)
  }
}

const loading = (text) => {
  return () => {
    return message.loading(text)
  }
}

const success = (text) => {
  return () => {
    return message.success(text)
  }
}

const MessageWrapper = {
  info,
  warn,
  warning,
  error,
  loading,
  success
}

export default MessageWrapper
