import moment from 'moment'
const loaded = JSON.parse(localStorage.getItem('__allied_db'))

const default_db = {
  manifests: {
    'cf28cac2-18ea-41f8': {
      id: 'cf28cac2-18ea-41f8',
      fromLocation: 'Walmart #42',
      datePickedUp: moment()
        .subtract(1, 'week')
        .toDate(),
      destLocation: 'ATMR Group',
      status: 'delivered',
      currentLocation: 'ATMR Group',
      transporter: 'Harry\'s Pickup Co.',
      step: '1',
      form: {
        '1':{},
        '2':{},
        '3':{},
        '4':{},
        '5':{},
        'adjustments':{},
        'final':{}
      }
    },
    '2ac6dcf6-836f-4509': {
      id: '2ac6dcf6-836f-4509',
      fromLocation: 'Local Auto Shop',
      datePickedUp: null,
      destLocation: 'ATMR Group',
      status: 'pending-pickup',
      currentLocation: 'SomeLocalAutoShop',
      transporter: null,
      step: '1',
      form: {
        '1':{},
        '2':{},
        '3':{},
        '4':{},
        '5':{},
        'adjustments':{},
        'final':{}
      }
    },
    '95f89f5f-320b-457d': {
      id: '95f89f5f-320b-457d',
      fromLocation: 'Walmart #70',
      datePickedUp: moment()
        .subtract(1, 'day')
        .toDate(),
      destLocation: 'ATMR Group',
      status: 'in-transit',
      currentLocation: 'Harry\'s Warehouse',
      transporter: 'Harry\'s Pickup Co.',
      step: '1',
      form: {
        '1':{},
        '2':{},
        '3':{},
        '4':{},
        '5':{},
        'adjustments':{},
        'final':{}
      }
    },
    '4aaf27a0-9b83-409f': {
      id: '4aaf27a0-9b83-409f',
      fromLocation: 'WeFixUrCar',
      datePickedUp: moment()
        .subtract(3, 'day')
        .toDate(),
      destLocation: 'Competition Inc.',
      status: 'in-transit',
      currentLocation: 'Truck Guys Warehouse #2',
      transporter: 'Truck Guys Co.',
      step: '1',
      form: {
        '1':{},
        '2':{},
        '3':{},
        '4':{},
        '5':{},
        'adjustments':{},
        'final':{}
      }
    },
    'd9c52da9-c39b-4c9b': {
      id: 'd9c52da9-c39b-4c9b',
      fromLocation: 'Dobb\'s Tire & Auto',
      datePickedUp: moment()
        .subtract(6, 'day')
        .toDate(),
      destLocation: 'ATMR Group',
      status: 'delivered',
      currentLocation: 'ATMR Group',
      transporter: 'Harry\'s Pickup Co.',
      step: '1',
      form: {
        '1':{},
        '2':{},
        '3':{},
        '4':{},
        '5':{},
        'adjustments':{},
        'final':{}
      }
    }
  }
}

let db

if (loaded && loaded !== {}) {
  db = loaded
} else {
  db = default_db
}

export function overwrite() {
  db = default_db
}

export function save() {
  localStorage.setItem('__allied_db', JSON.stringify(db))
}

export function getDB() {
  return db
}

export function getManifests() {
  return db.manifests
}

export function getManifest(id) {
  return db.manifests[id]
}

export function createManifest(m) {
  db.manifests[m.id] = m
}

