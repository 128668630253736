import React from 'react'
import {Typography, Table, Tag, Icon} from 'antd'
import {navigate} from '@reach/router'
import store from '../utils/fortune-db'
import moment from 'moment'

const {Text} = Typography

const totalCount = (manifest) => {
  let count = 0
  if (!manifest.form['1'])
    return count
  if (manifest.form['1'].passengerTireCount)
    count += manifest.form['1'].passengerTireCount
  if (manifest.form['1'].truckTireCount)
    count += manifest.form['1'].truckTireCount
  return count
}

const renderCount = (_, manifest) => {
  return (
    <Text>{totalCount(manifest)}</Text>
  )
}

const columns = [
  {
    title: 'Generator',
    dataIndex: 'fromLocation',
    key: 'fromLocation'
  },
  {
    title: 'Count',
    key: 'count',
    render: renderCount,
    sorter: (a, b) => totalCount(a) - totalCount(b)
  },
  {
    title: 'Pickup Date',
    dataIndex: 'datePickedUp',
    key: 'datePickedUp',
    render(date) {
      return date === null ?
        (<><Icon type="close-circle"/><Text> Pending</Text></>) :
        (<Text>{moment(date).format('MMM. D, YYYY')}</Text>)
    },
    sorter: (a, b) => a.datePickedUp - b.datePickedUp
  },
  {
    title: 'Transporter',
    dataIndex: 'transporter',
    key: 'transporter',
    render(transporter) {
      return transporter ?
        (<Text>{transporter}</Text>) : 
        (<Text>None</Text>)
    }
  },
  {
    title: 'Current Location',
    dataIndex: 'currentLocation',
    key: 'currentLocation'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => statusTags[status](),
    filters: [
      {
        text: 'Scheduled',
        value: 'pending-pickup'
      },
      {
        text: 'In Transit',
        value: 'in-transit'
      },
      {
        text: 'Delivered',
        value: 'delivered'
      },
      {
        text: 'Processed',
        value: 'processed'
      }
    ],
    onFilter: (filter, manifest) => manifest.status === filter,
    sorter: (a, b) => {
      const diff = statusValues[a.status] - statusValues[b.status]
      if (diff === 0) {
        return (a.datePickedUp - Date.now()) - (b.datePickedUp - Date.now())
      } else {
        return diff
      }
    },
    defaultSortOrder: 'ascend'
  },
  {
    title: 'Destination',
    dataIndex: 'destLocation',
    key: 'destLocation'
  },
]

const statusValues = {
  'pending-pickup': 1,
  'in-transit': 2,
  'delivered': 3,
  'processed': 4,
}

const statusTags = {
  'pending-pickup': () => <Tag color="geekblue">Scheduled</Tag>,
  'in-transit': () => <Tag color="orange">In Transit</Tag>,
  'delivered': () => <Tag color="cyan">Delivered</Tag>,
  'processed': () => <Tag color="lime">Processed</Tag>,
  'unknown': () => <Tag color="volcano"><em>UNKNOWN</em></Tag>
}

class ManifestTable extends React.Component {
  state = {
    data: [],
    loading: true,
    sizingOptions: {
      options: ['10'],
      defaultOption: 10
    }
  }

  async componentDidMount() {
    await new Promise(r => setTimeout(r, 250))
    const { payload: { records }} = await store.find('manifest')
    this.setState({ data: records, loading: false, sizingOptions: this.pageSizeOptions(records.length) })
  }

  pageSizeOptions = (length) => {
    const options = ['10', '20', '30', '40', '50', '100']
      .filter((_, i, a) => i > 0 ?  length >= parseInt(a[i-1]) : true)
    const defaultOption = options.length ? options.map(o => parseInt(o)).pop() : 10
    return {
      options,
      defaultOption
    }
  }

  getPagination = () => {
    return {
      defaultPageSize: 10,
      pageSizeOptions: this.state.sizingOptions.options,
      pageSize: this.state.sizingOptions.defaultOption,
      position: 'both',
      showSizeChanger: true
    }
  }

  render() {
    const { data, loading } = this.state
    return (
      <Table
        pagination={this.getPagination()}
        loading={loading}
        columns={columns}
        dataSource={data}
        rowSelection={{/*TODO?*/}}
        rowKey='id'
        onRow={(manifest) => {
          return {
            onClick: () => {navigate(`/manifest/${manifest.id}`)}
          }
        }}
        locale={{
          emptyText: 'No manifests :('
        }}
      />
    )
  }
}

/*const ManifestTable = () => {


  return (
    <>
      <Table columns={columns} dataSource={data} />
    </>
  )
}*/

export default ManifestTable
