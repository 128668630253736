import React from 'react'
import ManifestTable from '../ManifestTable'
import {PageHeader, Button} from 'antd'
import moment from 'moment'
import ManifestDetail from './ManifestDetail'
import StateDumpButton from '../debug/StateDumpButton'
import '../styles/ManifestView.css'
import {Link} from '@reach/router'

class ManifestView extends React.Component {
  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  render() {
    if (this.props.mid) {
      // TODO: display specific manifest if provided with an id
      return (
        <ManifestDetail id={this.props.mid}/>
      )
    } else {
      return (
        <>
          <PageHeader
            title="Manifests"
            subTitle={`since ${moment().subtract('3', 'month').format('MMM D, YYYY')}`}
            extra={[
              <StateDumpButton key='debug' componentRef={this.tableRef} />,
              <Link to="/manifest/new" key="1">
                <Button type="primary" icon="plus">Create New</Button>
              </Link>
            ]}
          >
          </PageHeader>
          <ManifestTable ref={this.tableRef} />
        </>
      )
    }
  }
}

export default ManifestView
