import {rgb} from 'pdf-lib'

class PDFRenderer {
  formLocations = {
    '1': {
      date: {
        x: 24,
        y: 89.3
      },
      registration: {
        x: 24,
        y: 108.3
      },
      areaCode: {
        x: 27.6,
        y: 136.5
      },
      phone: {
        x: 50,
        y: 136.5
      },
      companyName: {
        x: 24,
        y: 155.3
      },
      streetAddress: {
        x: 24,
        y: 174.5
      },
      city: {
        x: 24,
        y: 193.3
      },
      state: {
        x: 103,
        y: 193.3
      },
      zipCode: {
        x: 170,
        y: 193.3
      },
      passengerTireCount: {
        x: 23,
        y: 212.2
      },
      truckTireCount: {
        x: 136,
        y: 212.2
      },
      generatorChargedCheckYes: {
        x: 176.2,
        y: 240.5
      },
      generatorChargedCheckNo: {
        x: 204.8,
        y: 240.5
      },
      signerName: {
        x: 65,
        y: 297
      }
    },
    '2': {
      companyName: {
        x: 308,
        y: 89.3
      },
      registration: {
        x: 308,
        y: 108.3
      },
      driversLicenseNumber: {
        x: 410,
        y: 108.3
      },
      signerName: {
        x: 354,
        y: 136.5
      }
    },
    '3': {
      companyName: {
        x: 312,
        y: 193.3
      },
      registration: {
        x: 312,
        y: 212.2
      },
      driversLicenseNumber: {
        x: 414,
        y: 212.2
      },
      signerName: {
        x: 352,
        y: 240.7
      }
    },
    '4': {
      registration: {
        x: 23,
        y: 429.3
      },
      date: {
        x: 100,
        y: 429.3
      },
      passengerTireCount: {
        x: 24,
        y: 457.3
      },
      truckTireCount: {
        x: 105,
        y: 457.3
      },
      areaCode: {
        x: 27,
        y: 485
      },
      phone: {
        x: 42,
        y: 485
      },
      companyName: {
        x: 24,
        y: 513.3
      },
      streetAddress: {
        x: 24,
        y: 541.3
      },
      city: {
        x: 24,
        y: 570.3
      }
    },
    '5': {
      registration: {
        x: 307,
        y: 429.3
      },
      passengerTireCount: {
        x: 307,
        y: 457.3
      },
      truckTireCount: {
        x: 388,
        y: 457.3
      },
      areaCode: {
        x: 308.5,
        y: 485
      },
      phone: {
        x: 328,
        y: 485
      },
      companyName: {
        x: 307,
        y: 513.3
      },
      streetAddress: {
        x: 307,
        y: 541.3
      },
      city: {
        x: 307,
        y: 570.3
      }
    },
    'adjustments': {
      passengerTireCount: {
        x: 95,
        y: 363
      },
      truckTireCount: {
        x: 210,
        y: 363
      },
      reason: {
        x: 90,
        y: 382
      }
    },
    'final': {
      printNameOne: {
        x: 64,
        y: 650
      },
      printNameTwo: {
        x: 313,
        y: 650
      }
    }
  }

  getProps(height, font, section = '1') {
    const formLocations = this.formLocations
    return function(key) {
      let config = formLocations[section][key]
      if (!config) return { x: 0, y: 0 }
      return {
        x: config.x,
        y: height - config.y,
        size: 10,
        color: rgb(0.05, 0.05, 0.05),
        font
      }
    }
  }
}

export default PDFRenderer
