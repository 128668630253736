import React from 'react';
import { Typography } from 'antd';
import { Link } from '@reach/router';

const { Title } = Typography;

const OtherPage = props => {
  return (
    <div>
      <pre>Last render: {new Date().toISOString()}</pre>
      <Title level={2}>Here's another page!</Title>
      <Link to="/">Go home...</Link>
      {props.children}
    </div>
  );
};

export default OtherPage;
