import React from 'react'
import {PageHeader, Descriptions, Steps, Button, Popconfirm, message, Skeleton, Form, Select, Modal} from 'antd'
import {navigate, Link} from '@reach/router'
import store from '../../utils/fortune-db'
import '../styles/ManifestDetail.css'
import {ManifestForm} from './ManifestForm'
import StateDumpButton from '../debug/StateDumpButton'

const { Step } = Steps
const DescItem = Descriptions.Item
const { Option } = Select

const statusStrings = {
  'unknown': 'Unknown',
  'pending-pickup': 'Pending Pickup',
  'in-transit': 'In Transit',
  'delivered': 'Delivered'
}

const statusIndex = {
  'pending-pickup': 0,
  'in-transit': 1,
  'delivered': 2,
  'processed': 3,
  'unknown': 0
}

const ManifestStatus = (props) => {
  const {manifest} = props

  return (
    <Steps
      current={statusIndex[manifest.status]}
      style={{ padding: '12px 48px 36px' }}
    >
      <Step 
        title="Posted by generator"
        description={manifest.fromLocation}
      />
      <Step
        title="In Transit"
        description={manifest.transporter}
      />
      <Step
        title="Received by processor"
        description={manifest.destLocation}
      />
      <Step
        title="Finalized"
      />
      
    </Steps>
  )
}

const WrappedLocationForm = Form.create({
  name: 'location_form',
})(
  class LocationForm extends React.Component {
    render() {
      const { getFieldDecorator } = this.props.form
      const { options, visible, onCancel, onCreate } = this.props

      return (
        <Modal
          visible={visible}
          title='Set current location'
          okText='Set'
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form>
            <Form.Item label='Current Location'>
              {getFieldDecorator('currentLocation', {
                initialValue: options[0]
              })(
                <Select>{
                  options.map((item, index)=> <Option value={item} key={index}>{item}</Option>)
                }</Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      )
    }
  }
)

class ManifestDetail extends React.Component {

  state = {
    manifest: undefined,
    loading: true,
    formVisible: false
  }

  showForm = () => {
    this.setState({ formVisible: true })
  }

  hideForm = () => {
    this.setState({ formVisible: false })
  }

  handleSubmitForm = () => {
    if (!this.formRef) { return }
    const { form } = this.formRef.props
    form.validateFields((e, v) => {
      if (e) return
      this.updateLocation(this.props.id, v.currentLocation)
      form.resetFields()
      this.hideForm()
    })
  }

  async updateLocation(id, currentLocation) {
    const { payload: { records: [manifest] }}= await store.update('manifest', {
      id,
      replace: { currentLocation }
    })
    this.setState({ manifest })
  }

  saveFormRef = formRef => {
    this.formRef = formRef
  }

  async componentDidMount() {
    const { payload : { records: [manifest] }} = await store.find('manifest', this.props.id)
    this.setState({ manifest, loading: false })
  }

  deleteManifest = async () => {
    await store.delete('manifest', this.props.id)
    navigate('/manifest')
    message.warn(`Deleted manifest ${this.props.id}`)
  }

  render() { 
    const { manifest, loading } = this.state
    return manifest ? 
      (
        <>
          <PageHeader 
            title="Manifest: "
            subTitle={manifest.id}
            onBack={() => navigate('/manifest')}
            extra={[
              <StateDumpButton
                key='debug'
                componentState={this.state}
              />,
              <Link to={`/document/${manifest.id}`} key='0'>
                <Button icon="form">View PDF</Button>
              </Link>,
              <Link to={`/manifest/edit/${manifest.id}`} key='1'>
                <Button icon="form">Edit</Button>
              </Link>,
              <Button onClick={this.showForm} key='2'>Set Location</Button>,
              <Popconfirm
                title='Are you sure?'
                onConfirm={this.deleteManifest}
                onCancel={console.log}
                okText='Yes'
                cancelText='No'
                key='3'
              >
                <Button type='danger' icon='delete'>Delete</Button>
              </Popconfirm>
            ]}
          />
          <ManifestStatus manifest={manifest} />
          <Descriptions>
            <DescItem label="Generator">{manifest.fromLocation}</DescItem>
            <DescItem label="Picked up">{
              'Not picked up'
            }</DescItem>
            <DescItem label="Destination">{manifest.destLocation}</DescItem>
            <DescItem label="Status">{statusStrings[manifest.status]}</DescItem>
            <DescItem label="Current Location">{manifest.currentLocation}</DescItem>
            <DescItem label="Transporter">{manifest.transporter || '?'}</DescItem>
          </Descriptions>
          <WrappedLocationForm
            wrappedComponentRef={this.saveFormRef}
            visible={this.state.formVisible}
            onCancel={this.hideForm}
            onCreate={this.handleSubmitForm}
            options={[manifest.fromLocation, manifest.destLocation, manifest.transporter]}
          />
          <ManifestForm {...this.props} readonly={true}/>
        </>
      ) : loading ? (
        <Skeleton active />
      ) :(
        <div>
          <PageHeader
            title="Manifest not found"
            onBack={() => navigate('/manifest')}
          />
        </div>
      )
  }
}

export default ManifestDetail
