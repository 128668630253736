import {navigate} from '@reach/router'
import {message, Button, Checkbox, DatePicker, Form, Input, InputNumber, PageHeader, Select, Steps, Radio, Divider, Spin} from 'antd'
import moment from 'moment'
import React from 'react'
import sha256 from 'sha256'
import faker from 'faker/locale/en_US'
import store from '../../utils/fortune-db'
import getStates from '../../utils/states'
import MessageWrapper from '../../utils/MessageWrapper'
import '../styles/ManifestForm.css'

const { Step } = Steps
const { Option } = Select
const states = getStates()
const firstCol = 5
const secondCol = 12

const itemLayout = {
  colon: false,
  labelCol: { span: firstCol },
  wrapperCol: { span: secondCol }
}

const tailItemLayout = {
  colon: false,
  wrapperCol: {
    span: secondCol,
    offset: firstCol
  }
}

const steps = {
  '1': 0,
  '2': 1,
  '3': 2,
  '4': 3,
  '5': 4,
  'adjustments': 5,
  'final': 6
}

const WrappedManifestForm = Form.create({
  name: 'manifest_form',
  mapPropsToFields(props) {
    return {
      date: Form.createFormField({
        value: props.date
      }),
      registration: Form.createFormField({
        value: props.registration
      }),
      phone: Form.createFormField({
        value: props.phone
      }),
      companyName: Form.createFormField({
        value: props.companyName
      }),
      streetAddress: Form.createFormField({
        value: props.streetAddress
      }),
      streetAddress2: Form.createFormField({
        value: props.streetAddress2
      }),
      city: Form.createFormField({
        value: props.city
      }),
      state: Form.createFormField({
        value: props.state
      }),
      zipCode: Form.createFormField({
        value: props.zipCode
      }),
      passengerTireCount: Form.createFormField({
        value: props.passengerTireCount
      }),
      truckTireCount: Form.createFormField({
        value: props.truckTireCount
      }),
      signerName: Form.createFormField({
        value: props.signerName
      }),
      generatorWasCharged: Form.createFormField({
        value: props.generatorWasCharged
      }),
      status: Form.createFormField({
        value: props.status,
      }),
      assignedTransporter: Form.createFormField({
        value: props.assignedTransporter
      }),
      assignedDestination: Form.createFormField({
        value: props.assignedDestination
      })
    }
  },
  onValuesChange(props, changes) {
    props.onChange(changes)
  }
})((props) => {
  const { getFieldDecorator } = props.form
  const { handleSignButton, handleSubmit, handleGenerateData } = props
  const innerItemProps = {
    disabled: props.viewingOnly
  }

  return (
    <Form layout={'horizontal'} {...itemLayout} onSubmit={handleSubmit}>
      {(!(props.viewingOnly)) ? (
        <Form.Item label='TESTING: Generate Data'>
          <Button type='danger' onClick={handleGenerateData}>Generate</Button>
        </Form.Item>
      ) : null}
      <Form.Item label='Status'>
        {getFieldDecorator('status', {})(
          <Radio.Group {...innerItemProps}>
            <Radio.Button value="pending-pickup">Pending</Radio.Button>
            <Radio.Button value="in-transit">In Transit</Radio.Button>
            <Radio.Button value="delivered">Delivered</Radio.Button>
            <Radio.Button value="processed">Processed</Radio.Button>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label='Assigned Destination'>
        {getFieldDecorator('assignedDestination', {})(
          <Input {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label='Assigned Transporter'>
        {getFieldDecorator('assignedTransporter', {})(
          <Input {...innerItemProps}/>
        )}
      </Form.Item>
      <Divider />
      <Form.Item label="Date and Time of Pickup" >
        {getFieldDecorator('date', {})(
          <DatePicker
            format={'M/D/YYYY h:mm A'}
            showTime={{ format: 'HH:mm', minuteStep: 15 }}
            {...innerItemProps}
          />
        )}
      </Form.Item>
      <Form.Item label="Registration / Type of Generator">
        {getFieldDecorator('registration', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="Telephone Number">
        {getFieldDecorator('phone', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="Company Name">
        {getFieldDecorator('companyName', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="Street Address">
        {getFieldDecorator('streetAddress', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="Street Address (line 2)">
        {getFieldDecorator('streetAddress2', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="City">
        {getFieldDecorator('city', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="State">
        {getFieldDecorator('state', { initialValue: 'TX' })(
          <Select
            {...innerItemProps}
            showSearch
            placeholder="Select State"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
          >
            {states.map(item => <Option value={item.code} key={item.code}>{item.name}</Option>)}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Zip">
        {getFieldDecorator('zipCode', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="# of Whole Passenger Tires">
        {getFieldDecorator('passengerTireCount', {})(
          <InputNumber step={100}  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="# of Whole Truck Tires">
        {getFieldDecorator('truckTireCount', {})(
          <InputNumber step={100}  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item label="Print Name">
        {getFieldDecorator('signerName', {})(
          <Input  {...innerItemProps}/>
        )}
      </Form.Item>
      <Form.Item {...tailItemLayout}>
        {getFieldDecorator('generatorWasCharged', { 'valuePropName': 'checked' })(
          <Checkbox  {...innerItemProps}>
            Has the generator been charged for this service?
          </Checkbox>
        )}
      </Form.Item>
      {(!props.viewingOnly) ? (
        <Form.Item {...tailItemLayout}>
          <Button onClick={handleSignButton} size="small" type="primary" {...innerItemProps}>Digital Signature</Button>
        </Form.Item>
      ) : null}
      {(!props.viewingOnly) ? (
        <Form.Item {...tailItemLayout}>
          <Button htmlType="submit" type="primary" {...innerItemProps}>{`${props.submitString} Manifest`}</Button>
        </Form.Item>
      ) : null}
    </Form>
  )
})

const createManifest = (fields) => {
  let one = {}, two = {}, three = {}, four = {}, five = {}, adjustments = {}, final = {}
  if (fields.manifestObject) {
    if (fields.manifestObject.form['1']) {
      one = { ...fields.manifestObject.form['1'] }
    }
    if (fields.manifestObject.form['2']) {
      two = { ...fields.manifestObject.form['2'] }
    }
    if (fields.manifestObject.form['3']) {
      three = { ...fields.manifestObject.form['3'] }
    }
    if (fields.manifestObject.form['4']) {
      four = { ...fields.manifestObject.form['4'] }
    }
    if (fields.manifestObject.form['5']) {
      five = { ...fields.manifestObject.form['5'] }
    }
    if (fields.manifestObject.form['adjustments']) {
      adjustments = { ...fields.manifestObject.form['adjustments'] }
    }
    if (fields.manifestObject.form['final']) {
      final = { ...fields.manifestObject.form['final'] }
    }
  }
  let currentLocation = ''
  switch (fields.status) {
  case 'pending-pickup':
    currentLocation = fields.companyName
    break
  case 'in-transit':
    currentLocation = fields.assignedTransporter
    break
  case 'delivered':
    currentLocation = fields.assignedDestination
    break
  case 'processed':
    currentLocation = fields.assignedDestination
    break
  default:
    currentLocation = fields.companyName
  }
  const data = {
    date: fields.date.toDate(),
    registration: fields.registration,
    phone: fields.phone,
    companyName: fields.companyName,
    streetAddress: fields.streetAddress,
    streetAddress2: fields.streetAddress2,
    city: fields.city,
    state: fields.state,
    zipCode: fields.zipCode,
    passengerTireCount: fields.passengerTireCount,
    truckTireCount: fields.truckTireCount,
    signerName: fields.signerName,
    generatorWasCharged: fields.generatorWasCharged,
    signature: fields.signature
  }
  switch (fields.step) {
  case '1':
    one = {
      ...one,
      ...data
    }
    break
  case '2':
    two = {
      ...two,
      ...data
    }
    break
  case '3':
    three = {
      ...three,
      ...data
    }
    break
  case '4':
    four = {
      ...four,
      ...data
    }
    break
  case '5':
    five = {
      ...five,
      ...data
    }
    break
  case 'adjustments':
    adjustments = {
      ...adjustments,
      ...data
    }
    break
  case 'final':
    final = {
      ...final,
      ...data
    }
    break
  default:
  }
  const manifest = {
    fromLocation: fields.companyName,
    datePickedUp: fields.date.toDate(),
    destLocation: fields.assignedDestination,
    status: fields.status,
    currentLocation,
    transporter: fields.assignedTransporter,
    step: fields.step,
    form: {
      '1': { ...one, },
      '2': { ...two },
      '3': { ...three },
      '4': { ...four },
      '5': { ...five },
      adjustments: { ...adjustments },
      final: { ...final }
    }
  }
  return manifest
}

class ManifestFormProvider extends React.Component {

  state = {
    loading: true,
    step: '1',
    status: undefined,
    assignedTransporter: undefined,
    assignedDestination: undefined,
    date: undefined,
    registration: undefined,
    phone: undefined,
    companyName: undefined,
    streetAddress: undefined,
    streetAddress2: undefined,
    city: undefined,
    state: undefined,
    zipCode: undefined,
    passengerTireCount: undefined,
    truckTireCount: undefined,
    signerName: undefined,
    generatorWasCharged: undefined,
    signature: undefined,
    manifestObject: undefined
  }

  async componentDidMount() {
    if (!this.props.id) {
      console.log('no load')
      this.setState({ loading: false })
    } else {
      console.log(this.props.id)
      const { payload: { records: [ manifest ]}} = await store
        .find('manifest', this.props.id)
      if (manifest) {
        const step = manifest.step || this.state.step
        const newState = {
          loading: false,
          step,
          status: manifest.status,
          assignedTransporter: manifest.transporter,
          assignedDestination: manifest.destLocation,
          manifestObject: manifest,
          ...this.getFormSection(manifest, step)
        }
        this.setState(newState)
      }
    }
  }

  getFormSection = (manifest, step = '1') => {
    return {
      registration: undefined,
      phone: undefined,
      companyName: undefined,
      streetAddress: undefined,
      streetAddress2: undefined,
      city: undefined,
      state: undefined,
      zipCode: undefined,
      passengerTireCount: undefined,
      truckTireCount: undefined,
      signerName: undefined,
      generatorWasCharged: undefined,
      signature: undefined,
      ...manifest.form[step],
      date: manifest.form[step].date ? moment(manifest.form[step].date) : undefined
    }
  }

  handleSignButton = (e) => {
    e.preventDefault()
    const signature = sha256(new Date().toString())
    this.setState({ signature })
  }

  displayMessage = (text) => {
    return function displayMessage() {
      message.info(text)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const manifest = createManifest(this.state) 
    if (this.props.editing === true) {
      store.update('manifest', {
        id: this.props.id,
        replace: manifest
      }).then(({payload:{records:[manifest]}}) => {navigate(`/manifest/${manifest.id}`)})
        .then(MessageWrapper.success('Saved'))
    } else {
      store.create('manifest', [manifest])
        .then(({payload:{records:[manifest]}}) => {navigate(`/manifest/${manifest.id}`)})
        .then(MessageWrapper.success('Successfully created manifest'))
    }
  }

  handleChange = (changedFields) => {
    this.setState(changedFields)
  }

  handleGenerateData = () => {
    message.warn('Generating data...')
    const status = this.state.status || faker.random.arrayElement(['pending-pickup', 'in-transit', 'delivered', 'processed'])
    const step = this.state.step || faker.random.arrayElement(['1', '2', '3', '4', '5', 'adjustments', 'final'])
    let date = undefined
    switch (status) {
    case 'pending-pickup':
      date = faker.date.between(moment(), moment().add(14, 'days'))
      break
    case 'in-transit':
      date = faker.date.between(moment().subtract(4, 'days'), moment())
      break
    case 'delivered':
      date = faker.date.between(moment().subtract(10, 'days'), moment().subtract(4, 'days'))
      break
    case 'processed':
      date = faker.date.between(moment().subtract(1, 'month'), moment().subtract(10, 'days'))
      break
    default:
      date = faker.date.between(moment().subtract(1, 'month'), moment().add(1, 'month'))
    }
    const upperForm = {
      step,
      status,
      assignedTransporter: faker.company.companyName(0),
      assignedDestination: faker.company.companyName(0),
    }
    let form = {
      date: moment(date),
      registration: faker.random.alphaNumeric(16).toUpperCase(),
      phone: faker.phone.phoneNumberFormat().replace(/-/g, ''),
      companyName: faker.company.companyName(0),
      streetAddress: faker.address.streetAddress(),
      streetAddress2: faker.address.secondaryAddress(),
      city: faker.address.city(),
      state: faker.address.state(),
      zipCode: faker.address.zipCode(),
      passengerTireCount: faker.random.number(2000),
      truckTireCount: faker.random.number(2000),
      signerName: faker.name.findName(),
      generatorWasCharged: faker.random.arrayElement([true, true, true, false]),
      signature: faker.random.uuid()
    }
    if (!this.state.status) {
      form = {
        ...form,
        ...upperForm
      }
    }
    this.setState(form)
  }

  changeStep = (current) => {
    if (this.state.loading)
      return
    console.log(current)
    let step
    if (current === 5)
      step = 'adjustments'
    else if (current === 6)
      step = 'final'
    else
      step = '' + (current + 1)
    console.log(step)
    let newState = {
      step
    }
    if (this.state.manifestObject)
      newState = {
        step,
        ...this.getFormSection(this.state.manifestObject, step)
      }
    this.setState(newState)
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          id='spin-wrapper'
          style={{textAlign:'center'}}
        >
          <Spin
            size='large'
          />
        </div>
      )
    } else {
      return (
        <>
          <Steps
            size='small'
            type="navigation"
            current={steps[this.state.step] || 0}
            onChange={this.changeStep}
          >
            <Step title="Generator" />
            <Step
              title="Transporter (1)"
            />
            <Step
              title="Transporter (2)"
            />
            <Step title="Processor" />
            <Step title="Storage/Disposal" />
            <Step title="Adjustments" />
            <Step title="Finalized" />
          </Steps>
          <WrappedManifestForm
            {...this.state}
            onChange={this.handleChange}
            handleSignButton={this.handleSignButton}
            handleSubmit={this.handleSubmit}
            handleGenerateData={this.handleGenerateData}
            viewingOnly={this.props.readonly}
            editing={this.props.editing}
            submitString={this.props.editing ? 'Save' : 'Create'}
          />
        </>
      )
    }
  }
}

class ManifestFormPage extends React.Component {
  handleBack = () => {
    if (this.props.id && (this.props.editing || this.props.readonly)) {
      navigate(`/manifest/${this.props.id}`)
    } else {
      navigate('/manifest')
    }
  }

  render() {
    return (
      <div className="manifest-form-page">
        <PageHeader
          title="Manifest Form"
          onBack={this.handleBack}
        />
        <ManifestFormProvider {...this.props} />
      </div>
    )
  }
}

export {
  ManifestFormPage,
  ManifestFormProvider as ManifestForm
}
