import React from 'react'
import {Button, Modal } from 'antd'

class StateDumpButton extends React.Component {

  getDumpObject() {
    if (this.props.componentRef && this.props.componentRef.current) {
      return (this.props.componentRef.current.state)
    }
    if (this.props.componentState) {
      return (this.props.componentState)
    }
  }

  doDump = () => {
    const dump = this.getDumpObject()
    console.log(dump)

    Modal.warn({
      content: (
        <pre>
          {JSON.stringify(dump, null, 2)}
        </pre>
      ),
      width: 800
    })
  }

  render() {
    return (
      <Button
        icon='bug'
        type='danger'
        shape='circle'
        ghost
        onClick={this.doDump}
      />
    )
  }
}

export default StateDumpButton
