import store from './fortune-db'

async function drop(type) {
  const { payload: { records }} = await store.find(type)
  
  if (records.length) {
    const ids = records.reduce((r, c) => {
      return [...r, c.id]
    }, [])
    if (ids.length)
      await store.delete(type, ids)
  }
}

export default async function bootstrap() {
  await drop('manifest')
  await drop('user')

  await store.create('user', [{ username: 'admin', password: 'admin' }])
  await store.create('manifest', [{
    fromLocation: 'Walmart #42',
    datePickedUp: new Date(),
    destLocation: 'ATMR Group',
    status: 'delivered',
    currentLocation: 'ATMR Group',
    transporter: 'Harry\'s Pickup Co.',
    step: '1',
    form: {
      '1':{},
      '2':{},
      '3':{},
      '4':{},
      '5':{},
      'adjustments':{},
      'final':{}
    }
  }])
}

