import { Router, Redirect } from '@reach/router'
import { Layout, Menu, Icon } from 'antd'
import React from 'react'
import NotFound from '../layout/404.jsx'
import OtherPage from '../layout/OtherPage'
import DisplayParam from '../components/DisplayParam'
import ManifestView from '../components/views/ManifestView'
import PDFView from '../components/views/PDFView'
import { ManifestFormPage as ManifestForm } from '../components/views/ManifestForm'

const { Content, Footer, Header } = Layout

class MainLayout extends React.Component {

  state = {
    collapsed: false,
  }

  toggleSidebar = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }

  render() {
    if (!this.props.isLoggedIn) {
      return (
        <Redirect to="/login" />
      )
    } else {
      return (
        <Layout className="layout">
          <Header style={{background:'#fff'}}>
            <div className='nav-header'>
              <div className='logo' />
              <Menu
                theme='light'
                mode='horizontal'
                style={{lineHeight:'64px'}}
                defaultSelectedKeys={['1']}
              >
                <Menu.Item key='1'>
                  <Icon type='solution' /><span className='nav-text'>Manifest Dashboard</span>
                </Menu.Item>
              </Menu>
            </div>
          </Header>
          <Content style={{ padding: '16px 50px' }}>
            <div style={{background:'#fff', padding:'0px 24px 24px 24px', minHeight: 280, maxWidth: 1200, margin: 'auto'}}>
              <Router>
                <ManifestView path="/"/>
                <ManifestForm path="/manifest/new"/>
                <ManifestForm path="/manifest/edit/:id" editing={true}/>
                <ManifestForm path="/manifest/details/:id" readonly={true}/>
                <ManifestView path="/manifest"/>
                <ManifestView path="/manifest/:mid"/>
                <PDFView path='/document/:id' />
                <OtherPage path="/other">
                  <DisplayParam path="/" param="No parameter specified!"/>
                  <DisplayParam path=":param"/>
                </OtherPage>
                <NotFound default/>
              </Router>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Allied Dashboard &copy;2019</Footer>
        </Layout>
      )
    }
  }
}
/*            <Sider
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={this.toggleSidebar}
              theme='light'
              width={200}
            >
              <SidebarMenu isLoggedIn={this.state.isLoggedIn}/>
              </Sider>*/

export default MainLayout
